import React from "react";
import { Form, Container, Row, Accordion, Card, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Trans } from "react-i18next";
import { FilePond } from "react-filepond";
import { toast } from "react-toastify";
import ImageViewer from "../../Common/ImageViewer/ImageViewer";
import { Label } from "semantic-ui-react";
import AttachmentsDrawer from "../../Drawers/AttachmentsDrawer/AttachmentsDrawer";
import "./Assets.scss";
import TextareaAutosize from 'react-textarea-autosize';

class AssetForm extends React.Component {
  state = {
    assetName: this.props.assets.assetName || "",
    brand: this.props.assets.brand || "",
    model: this.props.assets.model || "",
    barcode: this.props.assets.barcode || "",
    dateOfPurchase: this.props.assets.dateOfPurchase || new Date(),
    note: this.props.assets.note || "",
    currentStep: this.props.currentStep || 1,
    attachments: this.props.assets.attachments,
    frontFacingAttachments: this.props.assets.frontFacingAttachments,
    compliancePlateAttachments: this.props.assets.compliancePlateAttachments,
    invitationId: this.props.invitationId || null,
    assetIndex: this.props.assetIndex || 0,
    assetUniqId: this.props.assetUniqId || "",
    isValidationError: false,
    validationErrorMessage: "",  
  };

  constructor(props) {
    super(props);
    this.assetName = React.createRef();
    this.brand = React.createRef();
    this.model = React.createRef();
    this.barcode = React.createRef();
    this.note = React.createRef();
  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChange = (date) => {
    this.setState({ dateOfPurchase: date });
  };

  handleFileChange = (e) => {
    this.setState({ attachment: e.target.files[0] });
  };

  handleSubmit = () => {
    //this.setState({ currentStep: 4 });
    this.props.goToLastStep(this.state , 4);
  };
  
  addToList = () => {
      if (this.state.assetName == '' || this.state.assetName == null) {
          this.setState({
              isValidationError: true,
              validationErrorMessage: "Please add the Asset name"
          });
      } else if (this.state.frontFacingAttachments.length == 0 && this.state.compliancePlateAttachments.length == 0) {
          this.setState({
              isValidationError: true,
              validationErrorMessage: "Please add at least one photo of an asset."
          });
      } else {
          this.setState({
              isValidationError: false
          });
          this.props.addToList(this.state);
      }

  };

  
  render() {
    return (
        <React.Fragment>
      <Container className="d-flex form-color justify-content-center">
        <div className="justify-content-center pb-4">
            {this.state.invitationId == null ?
            <h3 className="container fs-20 text-center text-purple fw-500 py-5">
              Asset Details
            </h3>
            : ''}
            <Form className="form asset-form" onSubmit={this.handleSubmit}>
              <div className="pt-2">
              <Form.Label>Asset Name</Form.Label>
                <input
                  type="text"
                  className="form-control shadow-sm bg-white rounded"
                  aria-label="assetName"
                  ref={this.assetName}
                  value={this.state.assetName}
                  onChange={(e) => this.setState({ assetName: e.target.value })}
                ></input>
              </div>
              <div>
                <Form.Label>Manufacturer</Form.Label>
                <input
                  type="text"
                  className="form-control shadow-sm bg-white rounded"
                  aria-label="brand"
                  ref={this.brand}
                  value={this.state.brand}
                  onChange={(e) => this.setState({ brand: e.target.value })}
                ></input>
              </div>
              <div>
                <Form.Label>Model</Form.Label>
                <input
                  type="text"
                  className="form-control shadow-sm bg-white rounded"
                  aria-label="model"
                  ref={this.model}
                  value={this.state.model}
                  onChange={(e) => this.setState({ model: e.target.value })}
                ></input>
              </div>
              <div>
                <Form.Label>Barcode</Form.Label>
                <input
                  type="text"
                  className="form-control shadow-sm bg-white rounded"
                  aria-label="barcode"
                  ref={this.barcode}
                  value={this.state.barcode}
                  onChange={(e) => this.setState({ barcode: e.target.value })}
                ></input>
              </div>
              <div className="datepicker-input">
                <Form.Label>Date of Purchase</Form.Label>
                <DatePicker
                  dateFormat={"dd/MM/yyyy"}
                  selected={new Date(this.state.dateOfPurchase)}
                  onChange={this.handleDateChange}
                  className="form-control shadow-sm bg-white rounded"
                />
                <img src="/Images/calendar_icon.svg"/>
              </div>
              <div>
                <Form.Label>Note</Form.Label>
                <TextareaAutosize 
                className="form-control text-area-input shadow-sm bg-white rounded"
                style={{
                  height: '60px!important',           
                  overflow: 'hidden',      
                  border: '1px solid #eff3f6',
                  fontSize: '13px', 
                  padding: '3px 10px',
                }}
                aria-label="note"
                  ref={this.note}
                  value={this.state.note}
                  onChange={(e) => this.setState({ note: e.target.value })}
                />
              </div>
              {this.state.invitationId == null ?
                  <div className='justify-content-around w-100'>
                    <Form.Label></Form.Label>
                    <div className="form-group">
                      <div className="img-upld-cont">
                        <AttachmentsDrawer
                          assetId={this.props.assets.id}
                          assetSubmittedUserId={this.props.encriptedUserId}
                          assetAttachments={this.state.attachments}
                          onSubmitAttachments={(event) => {
                            this.setState({ attachments: event });
                          }}
                          isJobPost={false}
                          isFromBot={false}
                          isFromAsset={true}
                          className="shadow-sm bg-white rounded"
                        ></AttachmentsDrawer>
                        {this.props.assets.attachments ? (
                          <ImageViewer
                            RemoveFromList={(fileid) => {
                              this.removeFromList(fileid, 0);
                            }}
                            attachments={this.state.attachments}
                            isFromBot={false}
                            isFromTenantManageJobPost={false}
                            isFromAsset={true}
                          ></ImageViewer>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  : <div className='d-flex justify-content-around w-100'>
                        <div className='pr-2'>
                            <Form.Label>Front Facing Photo</Form.Label>
                            <div className="form-group text-center">
                                <div className="img-upld-cont">
                                    {this.state.frontFacingAttachments.length < 1 ?
                                        <AttachmentsDrawer
                                            assetId={this.props.assets.id}
                                            assetSubmittedUserId={this.props.encriptedUserId}
                                            assetAttachments={this.state.frontFacingAttachments}
                                            onSubmitAttachments={(event) => {
                                                this.setState({ frontFacingAttachments: event });
                                            }}
                                            isJobPost={false}
                                            isFromBot={false}
                                            isFromAsset={true}
                                            className="shadow-sm bg-white rounded"
                                        ></AttachmentsDrawer> : ""}
                                    {this.props.assets.frontFacingAttachments ? (
                                        <ImageViewer
                                            RemoveFromList={(fileid) => {
                                                this.removeFromList(fileid, 1);
                                            }}
                                            attachments={this.state.frontFacingAttachments}
                                            isFromBot={false}
                                            isFromTenantManageJobPost={false}
                                            isFromAsset={true}
                                        ></ImageViewer>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='pl-2'>
                            <Form.Label>Compliance Plate Photo</Form.Label>
                            <div className="form-group text-center">
                                <div className="img-upld-cont">
                                    {this.state.compliancePlateAttachments.length < 1 ?
                                        <AttachmentsDrawer
                                            assetId={this.props.assets.id}
                                            assetSubmittedUserId={this.props.encriptedUserId}
                                            assetAttachments={this.state.compliancePlateAttachments}
                                            onSubmitAttachments={(event) => {
                                                this.setState({ compliancePlateAttachments: event });
                                            }}
                                            isJobPost={false}
                                            isFromBot={false}
                                            isFromAsset={true}
                                            className="shadow-sm bg-white rounded"
                                        ></AttachmentsDrawer> : ""}
                                    {this.props.assets.compliancePlateAttachments ? (
                                        <ImageViewer
                                            RemoveFromList={(fileid) => {
                                                this.removeFromList(fileid, 2);
                                            }}
                                            attachments={this.state.compliancePlateAttachments}
                                            isFromBot={false}
                                            isFromTenantManageJobPost={false}
                                            isFromAsset={true}
                                        ></ImageViewer>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                  </div>
              }
            </Form>
        </div>
      </Container>
      {this.state.isValidationError ?
        <Label className="text-red text-center w-100 m-0">* {this.state.validationErrorMessage}</Label>
        : ''
      }
        <div className="w-100 mb-4">
          <div className="container d-flex align-items-center justify-content-center form-color">
            {this.state.invitationId == null ?
              <button
                className="bg-white btn bg-purple text-white btn-size border-radius-22-5 mb-4"
                onClick={() => this.handleSubmit()}
              >
                I'M DONE
              </button>
              : <button
                className="btn bg-light-purple text-purple btn-size border-radius-22-5 w-100 my-3"
                onClick={() => this.addToList()}>
                ADD
              </button>
            }
          </div>
        </div>
    </React.Fragment>
    );
  }

  removeFromList(fileId, type) {
      if (type == 0) {
          var attachments = this.state.attachments.filter(function (el) {
              return el.fileId !== fileId;
          });
          this.setState({
              attachments: attachments,
          });
      } else if (type == 1) {
          var ffAttachments = this.state.frontFacingAttachments.filter(function (el) {
              return el.fileId !== fileId;
          });
          this.setState({
              frontFacingAttachments: ffAttachments,
          });
      } else if (type == 2) {
          var cpAttachments = this.state.compliancePlateAttachments.filter(function (el) {
              return el.fileId !== fileId;
          });
          this.setState({
              compliancePlateAttachments: cpAttachments,
          });
      }
    
  }
}

export default AssetForm;
