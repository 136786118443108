import React from 'react';
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import ImageViewer from '../../Common/ImageViewer/ImageViewer';
import Moment from "react-moment";
import settingsService from "../../../Services/settingsService";
import jobService from "../../../Services/JobService";
import { withTranslation } from 'react-i18next';
import userService from '../../../Services/UserService';
import ExternalFeedbackDrawer from "../../Drawers/ExternalFeedbackDrawer/ExternalFeedbackDrawer";
import './ExternalJobDetails.scss';
import JobNotes from '../../Drawers/JobDetailsDrawer/JobNotes/JobNotes';
import JobNotesDrawer from '../../Drawers/JobNotesDrawer/JobNotesDrawer';
import { WorkflowStates, JobsListTypes, UserType, PropertyInspectionStatus } from "../../../Constants/Enums";
import JSZip from 'jszip';
import FileSaver from 'file-saver';

class ExternalJobDetails extends React.Component {

  state = {
    showAverageCostWhenOwnerApproval: false,
    isOrganizationSettingsLoaded: false,
    averageCostModel: {
      jobAverageCost: 0.00,
      jobCount: 0,
      confidenceLevel: 1
    },
    isAverageCostModelLoaded: false,
    selectedRoleForFeedBack: 0,
    showJobNotes: false
  }

  componentDidMount() {
    this.getOrgDetails();

  }

  getOrgDetails = () => {
    userService.getUserRoles().then(response => {
      let selectedRole = (response.length == 1) ? response[0] : response.find(a => a.isSelected);
      if (selectedRole == undefined) {
        selectedRole = response[0]
      }
      localStorage.setItem('orgId', selectedRole.organizationId);
      this.getOrganizationSettings(localStorage.getItem('orgId'));
      this.setState({ selectedRoleForFeedBack: selectedRole.roleId });
    });
  }

  getOrganizationSettings(organizationId) {
    const jobId = window.jobId;
    settingsService.getOrganizationSettingsByOrgId(organizationId)
      .then((res) => {
        this.setState({
          showAverageCostWhenOwnerApproval: res.organizationSettingsModel.showAverageCostWhenOwnerApproval,
          isOrganizationSettingsLoaded: true,
        });
        if (res.organizationSettingsModel.showAverageCostWhenOwnerApproval) {
          this.getPredictiveAverageCost(jobId);
        }
        else {
          this.getOwnerApprovalStatus(window.jobId, localStorage.getItem('exernalUserId'));
        }
      })
  }

  //for getting average job cost
  getPredictiveAverageCost = (jobId) => {
    try {
      jobService.GetPredictiveJobAverageCost(jobId)
        .then(data => {
          this.setState({ averageCostModel: data });
          this.setState({ isAverageCostModelLoaded: true });
          this.getOwnerApprovalStatus(window.jobId, localStorage.getItem('exernalUserId'));
        });
    } catch (error) {
      console.log(error);
    }
  }

  getOwnerApprovalStatus = (jobId, userId) => {
    this.setState({ isLoadingTimeline: true });
    if (jobId && userId) {
      jobService.GetOwnerApprovalStatus(jobId, userId).then(result => {

        this.setState({
          isLoadingTimeline: false,
          ownerApprovalStatus: result.status,
          urlExpired: result.isExpired
        });
      }, error => {
        console.log(error);
      });
    }
  }

  getPropertyKeyMessage(keyCollectionType, propertyType, t, customKeyCollectionValue) {

    if (propertyType === 1) {
      switch (parseInt(keyCollectionType)) {
        case 1:
          return <span>{t('lbl_propkey_case1')}</span>;

        case 2:
          return <span>{t('lbl_propkey_case2')}</span>;

        case 3:
          return <span>{t('lbl_propkey_case3')}</span>;

        case 5:
          return <span>{customKeyCollectionValue}</span>;
        default:
          break;
      }
    } else {
      switch (parseInt(keyCollectionType)) {
        case 1:
          return <span>{t('lbl_propkey_case4')}</span>

        case 2:
          return <span>{t('lbl_propkey_case5')}</span>

        case 3:
          return <span>{t('lbl_propkey_case6')}</span>
        case 5:
          return <span>{customKeyCollectionValue}</span>;
        default:
          break;
      }
    }
  }

  budgetFormatter(budgetId, budgetAmount, t) {

    switch (budgetId) {
      case 0:

        return <div className="table-data "> {t('lbl_budgetrange_other')}</div>;
      case 1:

        return <div className="table-data "> {t('lbl_budgetrange_under')} $500</div>;
      case 2:

        return <div className="table-data "> {t('lbl_budgetrange_between')} $500 - $1000</div>;
      case 3:

        return <div className="table-data "> {t('lbl_budgetrange_between')} $1000 - $2000</div>;
      case 4:
        return <div className="table-data "> {t('lbl_budgetrange_between')} $2000 - $5000</div>
      case 5:

        return <div className="table-data "> {t('lbl_budgetrange_over')} $5000</div>;
      case 7:

        return <div className="table-data "> {t('lbl_budgetrange_estimate')}</div>;
      case -999:
        return <div className="table-data "> {budgetAmount == 0 ? <span>{t('lbl_budgetrange_notsupplied')}</span> : '$ ' + budgetAmount.toFixed(2)}</div>;
    }
  }
  getConfidenceLevelClass(confidenceLevel) {
    switch (confidenceLevel) {
      case 1:
        return "confident-lbl confident-low";
      case 2:
        return "confident-lbl confident-medium";
      case 3:
        return "confident-lbl confident-high";
    }
  }

  urgencyFormatter(urgencyType, t) {
    switch (urgencyType) {
      case '-1':
      case "Emergency":
        return <div className='common-tag small red'>{t('lbl_urgenttype_case-1')}</div>;
      case '1':
      case "Urgently":
        return <div className='common-tag small red'>{t('lbl_urgenttype_case1')}</div>;
      case '2':
      case "Within a few days":
        return <div className='common-tag small badge-warning'>{t('lbl_urgenttype_case2')}</div>;
      case '3':
      case "Within a week":
        return <div className='common-tag small badge-primary'>{t('lbl_urgenttype_case3')}</div>;
      case '-999':
      case "By this date":
        return <div className='common-tag small green'>{t('lbl_urgenttype_case4')}</div>;
      default:
        return <div className='common-tag small'>{urgencyType === 0 ? "" : urgencyType}</div>;
    }
  }

  toggleJobNotesDrawer() {
    this.setState({ showJobNotes: !this.state.showJobNotes })
  }

  async downloadAllAttachments(){
    console.log(JSZip.version)
    const attachments = this.props.jobDetails.attachments;
    const links = [];
    const fileNames = [];
    for (const i in attachments){
      links.push(attachments[i].fileUrl)
      fileNames.push(attachments[i].fileName)
    }
    const zip = new JSZip();
    const zipFilename = this.props.jobDetails.jobNumber+'_Attachments';  
    links.forEach(function (url, i) {
      var filename = fileNames[i];
      let blob = fetch(url).then(r => r.blob());
      zip.file(filename, blob, { binary: true });
    });
    await zip.generateAsync({ type: 'blob' })
    .then(function (content) {
      FileSaver.saveAs(content, zipFilename);
    });
  
  }

  render() {

    const isTenant = localStorage.getItem("userType") == UserType.Tenant ? true : false ;

    const { isJobDetailsLoading, jobDetails, customStyle } = this.props;
    const { t } = this.props;
    var jobCompleteAttachments = jobDetails !== null && jobDetails.hasOwnProperty("attachments") && jobDetails.attachments != null ? jobDetails.attachments.filter(x => x.type == 5) : []; //JobCompleteProvider
    var jobAttachments = jobDetails !== null && jobDetails.hasOwnProperty("attachments") && jobDetails.attachments != null ? jobDetails.attachments.filter(x => x.type != 5) : [];
    return (
      <>
        {(isJobDetailsLoading) ?
          <h2 className="align-items-center d-block">
            <Loader type="ThreeDots" color={(customStyle.brand) ? customStyle.brand : "#DB3D44"} height={10} width={100} />
          </h2>
          :
          (jobDetails != null) ?
            <>
              <div className="job-details-data flex-column-section" style={{ color: (customStyle.textColor) ? customStyle.textColor : "" }}>
                <div>
                  <span className="common-tag small badge-drafted mr-2">#{jobDetails.jobNumber}</span>
                  {(jobDetails && jobDetails.isExternalConsumerJob) ? '' :
                    <div>{this.urgencyFormatter(jobDetails.jobUrgencyDisplay, t)}</div>}
                  {(jobDetails && jobDetails.isExternalConsumerJob) ? '' :
                    <div className="common-tag small yellow">{(jobDetails.statusDisplay == 'Job Approval Pending') ? <span>{t('lbl_job_completed')}</span> : jobDetails.statusDisplay}</div>}
                  <div className="float-lg-right mt-2 mt-lg-0 common-tag small light-gray">
                    {t('lbl_updated')}:<Moment format={(localStorage.DateFormat) ? localStorage.DateFormat.toUpperCase() + " HH:mm" : "DD/MM/YYYY HH:mm"}>
                      {jobDetails.lastUpdatedDateTime == '0001-01-01T00:00:00' ? jobDetails.postedOn : jobDetails.lastUpdatedDateTime}
                    </Moment>
                  </div>
                </div>
                <h3 className="font-22 font-500 my-3">{jobDetails.title}</h3>
                <p className="font-14 font-400" dangerouslySetInnerHTML={{ __html: jobDetails.description }}></p>

                {(jobDetails && (jobDetails.workflowStatusId == 6) || jobDetails.isExternalConsumerJob) ?
                  <div className="font-italic font-weight-normal common-tag small badge-primary" style={{ width: 'max-content' }}>Posted Date : <Moment format={(localStorage.DateFormat) ? localStorage.DateFormat.toUpperCase() + " HH:mm" : "DD/MM/YYYY HH:mm"}>
                    {jobDetails.postedOn}
                  </Moment></div> : ''}

                <div className="row mt-4">
                  {/* LEFT COL  */}
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between card-details-row">
                        <div className='col-12 col-lg-4 px-0'>
                          <img src="/Images/icon-prop.svg"></img>
                          <span className="ml-3">{(jobDetails && jobDetails.isExternalConsumerJob) ? t('lbl_location_placeholder') : t('lbl_property')}</span>
                        </div>
                        <div className="col-12 col-lg-8 mt-3 mt-lg-0 pl-0 text-lg-right">
                          <span>
                            {(jobDetails && jobDetails.isExternalConsumerJob) ? '' : <strong>{`${jobDetails.propertyName}, `}</strong>}
                            <span>{jobDetails.address}</span>
                          </span>
                        </div>
                      </div>
                      {(jobDetails && jobDetails.isExternalConsumerJob) ? '' :
                        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between card-details-row">
                          <div className="col-12 col-lg-4 px-0">
                            <img src="/Images/icon-serv.svg"></img>
                            <span className="ml-3">{t('lbl_service')}</span>
                          </div>
                          <div className="col-12 col-lg-8 mt-3 mt-lg-0 pl-0 text-lg-right">
                            {jobDetails.primaryJobTypeDisplay} - {jobDetails.secondaryJobTypeDisplay}
                          </div>
                        </div>
                      }
                      {(jobDetails && jobDetails.budgetId != null && (jobDetails.workflowStatusId == 6 || jobDetails.workflowStatusId == 9 || jobDetails.workflowStatusId == 11 || jobDetails.workflowStatusId == 7 || jobDetails.isExternalConsumerJob) && jobDetails.isUserPorpertyOwner) ?
                        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between card-details-row">
                          <div className="col-12 col-lg-4 px-0">
                            <img src="/Images/icon_budget.svg"></img>
                            <span className="ml-3">{t('lbl_budget')}</span>
                          </div>
                          <div className="col-12 col-lg-8 mt-3 mt-lg-0 pl-0 text-lg-right">
                            <span>{this.budgetFormatter(jobDetails.budgetId, jobDetails.budgetAmount, t)}</span>
                          </div>
                        </div>
                        : ''}
                      {(jobDetails && jobDetails.scheduleEventId != null) ?
                        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between card-details-row">
                          <div className="col-12 col-lg-4 px-0">
                            <img src="/Images/icon-schedule.svg"></img>
                            <span className="ml-3">Schedule</span>
                          </div>
                          <div className="col-12 col-lg-8 mt-3 mt-lg-0 pl-0 text-lg-right">
                            <div className="pt-0"><span className="font-600">Schedule Start </span>:  <Moment format="DD/MM/YYYY HH:mm">{jobDetails.scheduleFromDate}</Moment></div>
                            <div className="pt-0"><span className="font-600">Schedule End </span>:  <Moment format="DD/MM/YYYY HH:mm">{jobDetails.scheduleToDate}</Moment></div>
                          </div>
                        </div>
                        : ''
                      }
                      {jobDetails.issueTags != undefined && jobDetails.issueTags != null && jobDetails.issueTags.length > 0 &&
                        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between card-details-row" >
                          <div className="col-12 col-lg-4 px-0">
                            <img src="/Images/icon-tag.svg"></img>
                            <span className="ml-3">Selected Tag</span>
                          </div>
                          <div className="col-12 col-lg-8 mt-3 mt-lg-0 pl-0 text-lg-right">
                            {jobDetails.issueTags.map(x => <span>{x}</span>)}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  {/* RIGHT COL  */}
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="d-flex flex-column w-100">


                      {jobDetails.issueAreaInProperty && jobDetails.issueAreaInProperty != null && jobDetails.issueAreaInProperty.trim().length > 0 &&
                        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between card-details-row">
                          <div className="col-12 col-lg-4 px-0">
                            <img src="/Images/icon-loca.svg"></img>
                            <span className="ml-3">Location</span>
                          </div>
                          <div className="col-12 col-lg-8 mt-3 mt-lg-0 pl-0 text-lg-right">
                            <span>
                              <span>{jobDetails.issueAreaInProperty}</span>
                            </span>
                          </div>
                        </div>
                      }

                      {(jobDetails && jobDetails.isExternalConsumerJob) ? '' :
                        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between card-details-row">
                          <div className="col-12 col-lg-4 px-0">
                            <img src="/Images/icon-key-col.svg"></img>
                            <span className="ml-3">{t('lbl_key')}</span>
                          </div>
                          <div className="col-12 col-lg-8 mt-3 mt-lg-0 pl-0 text-lg-right">
                            <span>{this.getPropertyKeyMessage(jobDetails.propertyKeyCollectType, jobDetails.propertyTypeId, t, jobDetails.customKeyCollectionValue)}</span>
                          </div>
                        </div>
                      }
                      {(jobDetails && jobDetails.isExternalConsumerJob) ? '' :
                        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between card-details-row notes flex-wrap">
                          <div className="col-12 col-lg-4 px-0">
                            <img src="/Images/icons-images.svg"></img>
                            <span className="ml-3">{t('lbl_image_videos')}</span>
                          </div>
                          <div className="col-12 col-lg-8 mt-3 mt-lg-0 pl-0 text-lg-right mb-3">
                            {(jobDetails.hasOwnProperty("attachments")) ?
                              <div>
                                <div className="external-gallery external-job-attachments">
                                  <ImageViewer attachments={jobAttachments} isAfter={false} isExternal={true}></ImageViewer>
                                </div>
                              </div> : ''
                            }                            
                          </div>
                            {jobDetails.attachments && jobDetails.attachments.length > 0 ? (
                                <div className="col-12 col-lg-12 mt-3 mt-lg-0 pl-0 text-lg-right">
                                    <button className="view-btn my-sm-0" onClick={() => this.downloadAllAttachments()}>Download All</button>
                                </div>
                            ) : (
                                ''
                            )}       

                        </div>
                      }
                     {(!jobDetails.isExternalConsumerJob && (jobDetails.workflowStatusId == 7 || (jobDetails.workflowStatusId == 107 && jobDetails.isReadyForMonthlyPayment && jobDetails.isUserPorpertyOwner)) && !isTenant) &&
                        <>
                          {/* <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between card-details-row">
                            <div className="col-12 col-lg-4 px-0">
                              <img src="/Images/icon-invoice.svg"></img>
                              <span style={{ marginTop: "5px" }} className="ml-3">{"Invoice"} </span>
                            </div>
                            <div className="col-12 col-lg-8 mt-3 mt-lg-0 pl-0 text-lg-right">
                              {(jobDetails.hasOwnProperty("invoiceAttachments")) ?
                                <>
                                  <div className="external-gallery">
                                    <ImageViewer attachments={jobDetails.invoiceAttachments} isAfter={false} isExternal={true}></ImageViewer>
                                  </div>
                                </> : ''
                              }
                            </div>
                          </div> */}
                          {jobCompleteAttachments.length > 0 &&
                            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between card-details-row">
                              <div className="col-12 col-lg-5 px-0">
                                <img src="/Images/icon-trade-attach.svg"></img>
                                <span style={{ marginTop: "5px" }} className="ml-3">{"Trade Attachments"} </span>
                              </div>
                              <div className="col-12 col-lg-7 mt-3 mt-lg-0 pl-0 text-lg-right">
                                <div className="external-gallery">
                                  <ImageViewer attachments={jobCompleteAttachments} isAfter={false} isExternal={true}></ImageViewer>
                                </div>
                              </div>
                            </div>
                          }
                        </>
                      }
                      {jobDetails && localStorage.getItem("exernalUserId") != "" ? (
                        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between card-details-row notes">
                          <div className="col-12 col-lg-4 px-0">
                            <img src="/Images/icon-note.svg"></img>
                            <span className="ml-3">{t("lbl_notes")}</span>
                          </div>
                          <div className="col-12 col-lg-8 mt-3 mt-lg-0 pl-0 text-lg-right">
                            <button className="view-btn my-sm-0" onClick={this.toggleJobNotesDrawer.bind(this)}>View</button>
                            {(this.state.showJobNotes) ?
                              <JobNotesDrawer
                                showJobNotesDrawer={this.state.showJobNotes}
                                jobNotes={(jobDetails.jobNotes) ? jobDetails.jobNotes : []}
                                toggleJobNotesDrawer={this.toggleJobNotesDrawer.bind(this)} >
                              </JobNotesDrawer>
                              :
                              ''}
                          </div>
                        </div>
                      ) : ""
                      }
                    </div>
                  </div>
                </div>
                {(this.state.showAverageCostWhenOwnerApproval && this.state.isAverageCostModelLoaded && (jobDetails.workflowStatusId == 6 && (this.state.ownerApprovalStatus == 0 || this.state.ownerApprovalStatus == 6))) ?
                  <div className="row">
                    <div className="col-sm-12">
                      <div>
                        <p className={this.getConfidenceLevelClass(this.state.averageCostModel.confidenceLevel)}> {t("hdr_jobCost", { jobAverageCost: this.state.averageCostModel.jobAverageCost, jobCount: this.state.averageCostModel.jobCount })}</p>
                      </div>
                    </div>
                  </div>
                  :
                  ''
                }
              </div>

              {(jobDetails && !jobDetails.isExternalConsumerJob && jobDetails.tradees && jobDetails.tradees.length) > 0 ?
                <div className="row" >
                  <h3>{t('lbl_trade_details')}</h3>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div class="row">
                      <div class="col">{t('lbl_trade_name')} : {jobDetails.tradees[0].firstName} {jobDetails.tradees[0].lastName}</div>
                      <div class="col">{t('lbl_business_name')} : {jobDetails.tradees[0].businessName}</div>
                    </div>
                    <div className="row">
                      <div class="col">{t('lbl_mobile_number')} : {jobDetails.tradees[0].mobileNumber}</div>
                      <div class="col">{t('lbl_email')} : {jobDetails.tradees[0].email}</div>
                    </div>
                  </div>
                </div>
                : ''
              }

              {((jobDetails.workflowStatusId == 7 || jobDetails.workflowStatusId == 107) && this.state.selectedRoleForFeedBack !== 7) ? <ExternalFeedbackDrawer jobDetails={jobDetails}></ExternalFeedbackDrawer> : ''}

              {(jobDetails.isEnableTenantJobPostDisclaimer && jobDetails.hasOwnProperty("tenantJobPostDisclaimer") && jobDetails.workflowStatusId != 6 && jobDetails.workflowStatusId != 9 && jobDetails.workflowStatusId != 11) ?
                <div className="disclaimer-wrapper">
                  <img src="/Images/icon-exclam.svg" className='float-left mr-3'></img>
                  <div>
                    <h4 className="mb-1">{t('lbl_disclaimer')}</h4>
                    <div dangerouslySetInnerHTML={{ __html: jobDetails.tenantJobPostDisclaimer }}></div>
                  </div>
                  {/* <span><span className="font-600">'{jobDetails.tenantJobPostDisclaimer}'</span> </span> */}

                </div> : ''
              }
            </> : ''

        }
      </>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  try {

    return {
      jobDetails: state.Jobs.jobDetails,
      isJobDetailsLoading: state.Jobs.isJobDetailsLoading,
      dateFormat: state.User.dateTimeFormatMin
    };
  } catch (error) {
    console.log(error);
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserDetails: details => {
      dispatch({ type: "SET_USER_DETAILS", userDetails: details });
    },
    updateIsTenantField: isCurrentTenant => {
      dispatch({ type: "CHECK_CURRENT_TENANT", isCurrentTenant: isCurrentTenant });
    },
    addJobTypes: jobTypes => { dispatch({ type: 'SET_JOB_TYPES', jobTypes: jobTypes }) }
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExternalJobDetails));