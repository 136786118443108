import React, { useState } from "react";
import Drawer from "../../Common/Drawer/Drawer";
import bidService from "../../../Services/BidService";
import { connect } from "react-redux";
import { AXIOS } from "../../../Utilities/HttpRequest/Axios";
import { toast } from "react-toastify";
import { AppConfiguration } from "read-appsettings-json";

class DeclineQuotesDrawer extends React.Component {
  state = {
    showDeclineAllQuotesDrawer: false,
    disableDeclineButton:false
  };
  constructor(props) {
    super(props);
    this.description = React.createRef();
  }

  render() {
    let drawer = this.createDrawer();

    return (
      <div className="d-flex" style={{alignItems: "center"}}>
        <div className='col-9 pl-0'>
        <p class="confident-lbl confident-low w-100 mb-0"> If you want more information or have any questions, please reply to the email that you received which will go directly to your property manager.</p>
        </div>
        <div className='col-3 pl-0'>
        <button className="btn btn-outline-danger" onClick={this.toggleDeclineAllQuotesDrawer}>
          <i className="ba-icn ba-icn-calendar-gray"></i> Decline all quotes
        </button>
        </div>
        {drawer}
      </div>
    );
  }

  createDrawer = () => {
    const header = this.createDeclineAllQuotesHeader();
    const body = this.createDeclineAllQuotesBody();
    const footer = this.createDeclineAllQuotesFooter();

    return (
      <Drawer
        size={"md"}
        open={this.state.showDeclineAllQuotesDrawer}
        header={header}
        body={body}
        footer={footer}
      ></Drawer>
    );
  };

  createDeclineAllQuotesHeader = () => {
    return (
      <>
        <h2 className="d-flex align-items-center">Decline Quotes</h2>
        <div className="panel-header-actions">
          <button
            className="action-btn panel-close"
            onClick={this.toggleDeclineAllQuotesDrawer}
          >
            <i className="ba-icn ba-icn-close"></i>
          </button>
        </div>
      </>
    );
  };

  createDeclineAllQuotesBody = () => {
    return (
      <div className="panel-body">
        <div className="row">
          <div className="col-sm-12 text-left">
            <div className="form-group">
              <label>Note<span className="required-icon">*</span></label>
              <textarea
                className="form-control text-area-height"
                id="txtFeedback"
                rows="3"
                ref={this.description}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    );
  };

  createDeclineAllQuotesFooter = () => {
    return (
      <>
        <div className="card-actions">
        <button className="more-actions" onClick={this.toggleDeclineAllQuotesDrawer}>
            {" "}
            CANCEL{" "}
          </button>
          <button onClick={this.onDeclineAllQuotes} disabled={this.state.disableDeclineButton}>
            <i className="ba-icn ba-icn-ok"></i> DECLINE ALL QUOTES{" "}
          </button>
        </div>
      </>
    );
  };

  onDeclineAllQuotes = () => {
    if (this.description.current.value == '' || this.description.current.value == undefined || this.description.current.value == null) {
        toast.error("Please enter quotes decline reason.", {
            position: toast.POSITION.BOTTOM_RIGHT
        });
        return;
    }
    this.setState({disableDeclineButton:true})
    
    bidService.declineAllBidsByOwner(this.props.jobDetails.id, this.description.current.value).then(res => {
      if(res){
        toast.success('All bids decline by owner', {
            position: toast.POSITION.BOTTOM_RIGHT
        });
        this.toggleDeclineAllQuotesDrawer();
        this.props.toggleDrawer(true);
    }
    else{
      debugger;
      toast.error('Job status has been changed. Please contact the property manager for more details', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      this.setState({disableDeclineButton:false})
    }
  });
  }

  toggleDeclineAllQuotesDrawer = () => {
    this.setState(state => ({
      showDeclineAllQuotesDrawer: !this.state.showDeclineAllQuotesDrawer
    }));
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    isRefresh: state.MyJobs.isRefreshGrid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshGrid: isRefresh => {
      dispatch({ type: "REFRESH_GRID", isRefresh: isRefresh });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeclineQuotesDrawer);
